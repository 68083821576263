.activityDiv {
  background-color: #f3f5f7;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-left: 0.5rem solid white;
  border-right: 0.5rem solid white;

  .ant-collapse-borderless {
    background-color: transparent;
  }

  .ant-collapse-item {
    padding: 0;
    border-bottom: none !important;

    .ant-collapse-header {
      padding: 0;
      color: $color-hover-blue;
    }

    i {
      left: 2px;
    }
  }

  .activityDiv__Owner {
    color: $color-black;
  }
}

.formMetaCard {
  background: $color-white;
  box-shadow: 0px 3px 6px rgba(#000000, 0.06);
  width: 280px; // min-width: 309px;
  @media screen and (min-width: 575.98px) {
    position: fixed;
    z-index: 1000;
  }

  @media (max-width: 992px) {
    position: relative;
  }

  @media (min-width: 1200px) {
    width: 220px;
    // max-width: 300px;
  }

  @media (min-width: 1500px) {
    width: 363px !important;
  }

  .formMetaCard__Header {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    span {
      color: #707070;
      font-size: 18px;
      font-weight: 500;
    }

    .closeLink {
      color: $color-primary;
      font-size: 0.8rem;
      cursor: pointer;
    }
  }

  .formMetaCard__Body {
    padding: 0 20px 20px;
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;

    .formMetaCard__BodyRow {
      padding: 0.35rem 0;
      display: flex;
      flex-wrap: wrap;
    }

    .formMetaCard__BodyRowLabel {
      color: #8c8c8c;
      font-size: 14px;
      margin-right: 0.2rem;
    }

    .formMetaCard__BodyRowValue {
      color: #000000;
      font-size: 14px;
    }
  }

  .formMetaCard__Footer {
    background-color: #f5f5f5;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // flex-wrap: wrap;
    padding: 0.5rem;

    // div {
    //   flex-grow: 1;
    // }

    .formMetaCard__FooterBtns {
      display: flex;
      align-items: center;

      button {
        flex-grow: 1;
      }
    }
  }

  @media screen and (max-width: 767px) {
    position: fixed;
    bottom: -30px;
    left: 0;
    margin: 30px 0;
    width: 100%;
    padding: 0px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    .hidden {
      display: none;
    }
  }
}
