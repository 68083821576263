.subformQBuilder {
  .subformQCard_AddQuestion {
    color: $color-primary;
    margin: 1rem 0 3rem;
  }
}

.subformQCard_Container {
  background-color: #f5f6f8;
  margin-bottom: 1rem;

  .subformQCard_optionsDiv {
    transition: all 1s ease-in;
    padding: 0.8rem;

    .subformQCard_optionsDiv__Row1 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      .ant-input-number {
        width: 43% !important;
        margin-right: 5px;
      }
    }

    .subformQCard_optionsDiv__Row2 {
      display: flex;

      div {
        &:first-child {
          width: 41%;
        }

        &:last-child {
          width: 58%;
        }
      }
    }
  }

  .subformQCard_optionsDiv__AdvancedSection {
    margin-top: 2rem;

    .skipLogics__container {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 5px;
        top: -12px;
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid white;
      }

      &::after {
        content: '';
        position: absolute;
        left: 7px;
        top: -10px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
      }
    }
    .header {
      font-weight: bold;
      position: relative;

      span {
        background: #f5f6f8;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0.15rem;
        left: 8rem;
        right: 0;
        height: 0.5rem;
        border-top: 1px solid #e3e3e3;
      }
    }
  }

  .slideIn {
    display: block;
  }

  .slideOut {
    display: none;
  }

  .text-input,
  .insyt-textarea {
    background: white;
  }

  .item_list {
    background: white;
  }
}

.subformQCard {
  display: flex;
  justify-content: space-between;
  padding: 0.3rem;
  padding-left: 40px;
  flex-basis: auto;

  i {
    &:hover {
      cursor: pointer;
    }
  }

  .anticon-ellipsis {
    transform: rotate(90deg);
    margin-left: 5px;
  }
  .ant-input {
    width: 60%;
  }

  .ant-select {
    width: 22%;
  }

  .subformQCard_Configs {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8%;
  }
}

.subformQCard_handle {
  background: url('../../images/svgs/six.svg') no-repeat;
  background-size: 12px 38px;
  display: flex;
  align-items: center;
  width: 30px;
  height: 40px;
  position: absolute;

  span {
    margin-left: 15px;
  }
}
