.resDetailedView {
  display: flex;
  height: calc(103vh - 226px);
  border-top: 1px solid #dadada;
}

.detailedViewMain {
  display: flex;
  border-bottom: none;
  width: 100%;
  z-index: 1;

  .detailedViewMain_List {
    flex: 2;
    background: #fff;
    border-bottom: 1px solid #dadada;

    .detailedViewMain_ListErrorMsg {
      font-weight: 600;
      background: #ffe0b2;
      text-align: center;
      padding: 1rem 0;
      min-height: 52px;
    }
  }

  .detailedViewMain_Meta {
    flex: 1;
    padding: 0rem 2rem 1rem;
    max-width: 400px;
    overflow: auto;

    .detailedViewMain_Meta__itemsList {
      border-bottom: 1px solid #dadada;
      padding: 0 0 1rem;
      .div_Header {
        color: #a2a2a2;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
      }

      .detailedViewMain_Meta__item {
        padding: 1rem 0;
        p {
          margin: 0;
          &:first-child {
            color: #a2a2a2;
            font-size: 14px;
          }
        }

        span {
          color: black;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }
  }

  .detailedViewMain_Meta__ActionsDiv {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;

    button > span {
      margin: 0;
    }

    span {
      margin-top: 1rem;
      font-size: 15px;

      &:hover {
        cursor: pointer;
      }
    }

    .detailedViewMain_Meta__ActionsDivItem {
      margin-bottom: 1.5rem;

      .header {
        padding-bottom: 5px;
      }
    }
  }

  .detailedViewMain__question {
    color: #000;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 4px;
  }

  .detailedViewMain__response {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #2599ef;
    size: 18px;
    margin: 0;
    .detailedViewMain__responseInputContainer {
      flex: 1;
    }
  }

  .detailedViewMain__subFormContainer {
    background: #fcf6ee;
  }

  .detailedViewMain__subFormToggleUp {
    background: url('../../images/svgs/arrow-up.svg') no-repeat;
    background-size: 12px 20px;
    background-position: 90%;
  }

  .detailedViewMain__subFormToggleDown {
    background: url('../../images/svgs/arrow-down.svg') no-repeat;
    background-size: 12px 20px;
    background-position: 90%;
  }

  .detailedViewMain__subForm {
    padding: 0.5rem 0;
    border-bottom: 1px solid #f8ebda;

    &:hover {
      cursor: pointer;
    }
  }

  .detailedViewMain__res {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    min-height: 90px;
    border-bottom: 1px solid #f1f1f1;
    position: relative;

    // &:hover {
    //   background-color: #cee1f5;
    // }
  }

  .detailedViewMain__responseActionBtn {
    background: url('../../images/pngs/caret-down.png') no-repeat;
    background-color: white;
    background-position: 90%;
    box-shadow: 0 2px rgba(#000000, 0.12);
    border: 1px solid #dadada;
    font-weight: 600;
    font-size: 14px;
    padding: 0.5rem 2rem 0.5rem 0.9rem;
    height: 40px;
    width: 178px;
    z-index: 1000;
    cursor: pointer;
  }
}

.detailedSideScroll {
  height: 100%;
  width: 285px;

  .detailedSideScroll__link {
    background: url('../../images/svgs/feedback.svg') no-repeat;
    background-size: 22px 20px;
    background-position-y: 21px;
    background-position: 10% 65%;
    padding: 1rem 1rem 1rem 3.5rem;
    height: 52px;

    &:hover {
      cursor: pointer;
      background-color: #dbe7fd;
    }
  }

  .detailedSideScroll__linkActive {
    background-color: #fff;
    color: #2447b3;
    font-weight: 600;
    font-size: 15px;

    &:hover {
      cursor: pointer;
      background-color: #fff;
    }
  }
}
.review__Action {
  background: $color-secondary;
  cursor: pointer;
  padding: 0.5rem;
  width: 100%;
  border: none;

  span {
    color: white !important;
  }
}

.review__ActionCancel {
  border: 2px solid $color-secondary;
  cursor: pointer;
  padding: 0.5rem;
  width: 100%;
  margin-top: 0.5rem;

  span {
    color: $color-secondary !important;
  }
}

.detailedViewMain__CommentBox {
  background: #eaedf1;
  padding: 0.6rem 1rem;
  width: 100%;
  margin: 0.6rem 0;

  .detailedViewMain__CommentBox-comment {
    font-weight: bold;
  }

  .detailedViewMain__CommentBox-meta {
    font-size: 0.8rem;
  }
}
