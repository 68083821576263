.qBuilderPages {
  display: flex;
  justify-content: normal;
  align-items: center;

  div {
    // margin-right: 2rem;
  }
}
.qBuilderPages_Wrapper {
  position: fixed;
  z-index: 200;
  width: 100%;
  background-color: #f3f5f7;
  padding: 2.5rem 0 0.8rem 0;
  margin-top: -1.5rem;
}

.ant-dropdown-menu-item {
  span {
    font-size: 0.82rem !important;
  }
}
