@import './old_styles.css';
@import '../../node_modules/react-table/react-table.css';
@import '../../node_modules/react-grid-layout/css/styles.css';
@import '../../node_modules/react-resizable/css/styles.css';

@import './variables/_colors';
@import './variables/_utils';

@import './components/_sidebar';
@import './components/_topNavbar';
@import './components/_questionWidget';
@import './components/_tree';
@import './components/_analyticTypeCard';
@import './components/_resDetailedView.scss';
@import './components/_subformQCard.scss';
@import './components/_qBuilderPages.scss';
@import './components/_formMetaCard.scss';
@import './components/paymentModal.scss';
@import './components/_geofence.scss';
@import './components/detailedViewModal.scss';
@import './components/_formTemplateCard.scss';

@import './container/_map_report';
@import './container/_landingPage';
@import './container/_authWrapper';
@import "./container/_ReviewExercise";

@import './layouts/_settings';
@import './layouts/_typography';
@import './layouts/_spacing';
@import './layouts/_misc';

* {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

input[disabled] {
  pointer-events: none;
}

html,
body {
  height: 0;
  padding: 0;
  margin: 0;
  background-color: #f3f5f7;
}

.anticon {
  vertical-align: 0.01rem !important;
}

.ant-btn-primary {
  background-color: $color-primary;

  &:hover {
    background-color: $color-hover-blue;
  }
}

.inverse-tooltip {
  z-index: 2003 !important;

  .ant-tooltip-inner {
    padding: 6px 8px;
    color: black;
    text-align: left;
    text-decoration: none;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    min-height: 32px;
    word-wrap: break-word;
  }

  .ant-tooltip-arrow {
    // left: 3px;
    // border-width: 5px 5px 5px 0;
    border-right-color: white !important;
  }
}

.blue-tooltip {
  z-index: 2003 !important;
  left: 70px !important;

  .ant-tooltip-inner {
    padding: 6px 8px;
    color: white;
    text-align: left;
    text-decoration: none;
    background-color: #034574;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    min-height: 32px;
    word-wrap: break-word;
  }

  .ant-tooltip-arrow {
    // left: 3px;
    // border-width: 5px 5px 5px 0;
    border-right-color: #034574 !important;
  }
}

.ant-btn-primary.disabled,
.ant-btn-primary[disabled],
.ant-btn-primary.disabled:hover,
.ant-btn-primary[disabled]:hover,
.ant-btn-primary.disabled:focus,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary.disabled:active,
.ant-btn-primary[disabled]:active,
.ant-btn-primary.disabled.active,
.ant-btn-primary[disabled].active {
  background-color: #93acd9 !important;
}

.ant-btn-danger {
  border-color: $color-danger !important;
  background-color: $color-danger !important;
}

.ant-modal-confirm-body {
  i {
    display: none;
  }
}

.ant-modal-header {
  border-bottom: 0 !important;
}

.ant-radio-button-wrapper-checked {
  background-color: $color-primary !important;
  color: white !important;
}

.ant-modal-footer {
  border-top: 0 !important;
}

.ant-modal-mask {
  z-index: 10000 !important;
}

.ant-modal-wrap {
  z-index: 10001 !important;
}

.customSelect {
  width: 25%;
}

.container-fluidy {
  // @extend .container-fluid;
  padding: 0 1rem;
}

.esoko-app-bar {
  background: white;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  height: 64px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2024;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;

  .AppHeader-left {
    display: flex;
    align-items: center;

    h3 {
      color: $color-primary;
      font-weight: 600;
    }

    .logo {
      width: 35px;
      height: 35px;
      background: white;
      margin-right: 10px;
      border-bottom: 1px solid transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .AppHeader-right {
    padding-right: 20px;
  }
}

.MenuGridList {
  display: inline-block;
  width: 50%;
  transition: 0.2s ease-in;

  &:hover {
    cursor: pointer;
    background-color: rgba($color-grey, 1);
  }
}

.MenuGridItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem;

  i {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
  }
}

.exceluploadModal {
  display: flex;

  .ant-upload {
    width: 100% !important;
    padding: 0.5rem;
  }

  p {
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }
}

.exceluploadModal>span {
  width: 60%;
}

.ReactTable {
  font-size: 15px;
  border: none;
}

.ReactTable.-striped .rt-tbody .rt-tr {
  &:hover {
    cursor: pointer;
    background: #d3e0eb !important;
  }
}

p {
  margin: 0;
}

.rt-tr-group {
  &:nth-child(2n) {
    background: white;
  }
}

.rt-td {
  padding: 15px 2px !important;
}

.rt-thead {
  background: white;
  color: black;
  padding: 15px 2px;
}

.pointer {
  cursor: pointer;
}

.addQuestion__Btn {
  color: #98c6e5;
  border-color: #98c6e5;
  margin-bottom: 1rem;
}

.no-page-questions {
  display: flex;
  background-color: #eef0f3;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;

  .no-page-questions__Container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    svg {
      width: 130px;
      margin-bottom: 1rem;
    }

    p>span {
      color: black;
      font-size: 20px;
      font-weight: bold;
    }

    span {
      font-size: 0.8rem;
    }

    button {
      margin-top: 1.5rem !important;
    }
  }
}

.alt_sidebar_search {
  padding-bottom: 0.8rem;

  input {
    border: 0;
    border: 1px solid #ccc;
    border-radius: 50px;
    width: 100%;
    padding: 0.4rem;
    padding-left: 1rem;

    &:focus {
      outline: none;
    }
  }
}

.alt_sidebar_tabs {
  display: flex;

  .alt_sidebar_tab {
    background: #e0e2e4;
    color: #6e6f72;
    cursor: pointer;
    padding: 0.8rem;
    text-align: center;
    width: 50%;
    transition: 0.2s ease-in;
  }

  .active_alt_sidebar_tab {
    background: white !important;
    color: $color-primary !important;
    font-weight: bold;
  }

  p {
    margin: 0;
  }
}

.sideBar__reducer {
  position: fixed;
  top: 65px;
  margin-left: 235px;
  z-index: 2009;
  transition: all 0.3s ease-in;

  button {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border: none;
  }

  @media screen and (max-width: 767px) {
    display: none;
    visibility: hidden;
  }

  @media screen and (max-width: 1199px) {
    margin-left: 195px;
  }
}

.sideBar__reduced {
  @extend .sideBar__reducer;
  margin-left: 60px;
}

.dashmain {
  width: calc(100% - #{$sidebar-width});
  margin-left: $sidebar-width;
  transition: all 0.3s ease-in;

  @media screen and (max-width: 1199px) {
    width: calc(100% - #{$sidebar-width-s});
    margin-left: $sidebar-width-s;
  }

  @media screen and (max-width: 767px) {
    margin-left: 0;
    width: 100%;
  }
}

.dashmain-small {
  @extend .dashmain;
  width: calc(100% - #{$sidebar-width-narrow});
  margin-left: $sidebar-width-narrow;
}

.billingMainInfo {
  color: #de764b;
  background: #ffe7ba;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;

  &.expired {
    color: #fff;
    background: $color-danger;
  }

  p {
    color: #fff;
    border: 1px solid #282c2f;
    background-color: #282c2f;
    padding: 0.2rem 1rem;
    margin-left: 1.5rem;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    border-radius: 3px;

    &:hover {
      cursor: pointer;
      background-color: #000;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
  }
}

.app_header {
  // background: rgb(255, 255, 255) !important;
  position: fixed;
  width: calc(100% - #{$sidebar-width});
  z-index: 2000;
  // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  transition: all 0.3s ease-in;

  @media screen and (max-width: 1199px) {
    width: calc(100% - #{$sidebar-width-s});
  }

  @media screen and (max-width: 767px) {
    position: fixed;
    width: 100%;
    z-index: 2000;
  }
}

.app_header_small {
  @extend .app_header;
  width: calc(100% - #{$sidebar-width-narrow});

  @media screen and (max-width: 1199px) {
    width: calc(100% - #{$sidebar-width-narrow-s});
  }
}

.ins-btn-style {
  height: 46px !important;
  padding: 0 20px !important;
}

.auth_frame {
  background-color: #f8f9fd;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.login_ui_card {
  display: flex;
  height: 650px;
  min-height: 470px;
  width: 80vw;

  @media screen and (max-width: 767px) {
    height: 90vh;
    width: 100vw;
  }
}

.login_ui_card>div {
  height: 100%;
}

.login_ui_card>.login_info_window {
  color: #ffffff !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 45%;
}

.login_info_window>h3 {
  font-size: 1.5rem;

  letter-spacing: 0.15rem;
}

.login_info_window h1 {
  // font-weight: 500;
  font-size: 2.4rem;
  margin-bottom: 0;
  text-transform: capitalize;
}

.login_info_window p {
  font-weight: 300;
  text-transform: capitalize;
}

.login_info_window>h3,
.login_info_window>div {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 10%;
}

.login_ui_card>.login_form_window {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid #d1d1d1;
  justify-content: space-around;
  width: 55%;

  .login_form_window_innerDiv {
    width: 70%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    overflow: auto;

    .login_form_window_innerDiv {
      width: 100%;
    }
  }
}

.page-label {
  font-size: 1.5rem;
  margin-bottom: 1.1rem;

  @media screen and(max-width: 767px) {
    margin-bottom: 0.8rem;
  }
}

.heading-3-blue {
  font-size: 1.75rem;
  font-weight: 400;
  color: #50506f;
}

.link-blue span {
  color: #007bff;
  cursor: pointer;

  &:hover {
    color: #0056b3;
  }
}

.insyt-textarea {
  border: 1px solid #d9d9d9;
  padding: 6px 15px;
  margin: 0px;
  transition: all 0.3s ease-in-out;
  outline: none;
  width: 100%;
  font-size: 14px;
  height: 100px;
}

.descriptive-text {
  font-size: 0.9375rem;
  font-weight: 400;
  color: #747474;
  margin-top: 5px;
}

.topnav_formtitle {
  @media screen and (max-width: 767px) {
    margin-left: 30px;
  }
}

.login_href {
  text-decoration: none;
  color: #000000;
  font-weight: 300;
  transition: 0.3s ease-in;
}

.login_href:hover {
  color: #1f64af;
  text-decoration: underline;
}

.login_form_window>.login_ui_i18n_switcher {
  width: 100%;
  height: 10%;
  text-align: right;
}

.app_card {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin: 0 2rem;
  padding: 1.2rem;
  text-align: center;
  transition: 0.2s ease;
  height: 150px;
  width: 150px;
}

.app_card:hover {
  cursor: pointer;
}

@media only screen and (min-width: 1400px) {
  .login_ui_card {
    width: 70vw;
  }
}

.avatar-circle {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.avatar-cirlce .initials {
  font-size: 18px;
  margin: 0;
  padding: 0.2rem;
  line-height: 15px;
}

.avatar-circle-clickable:hover {
  cursor: pointer;
}

.survey-card {
  background: #ffffff;
  border-radius: 5px;
  height: 185px;
  border: 1px solid #e4ecf3;
  display: block;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
  position: relative;
  overflow: hidden;

  .survey-card__header {
    display: flex;
    padding: 1rem 0.2rem 0.5rem 1rem;
  }

  .survey-card__body {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    background: #e4ecf3;
    padding: 0.4rem 0.2rem 0.2rem 1rem;
  }

  @media screen and (max-width: 767px) {
    min-height: 100px;
  }

  .survey_card_title {
    color: #000000;
    width: 100%;
    height: 100%;
    font-size: 14px;
    font-weight: bold;

    @media screen and (max-width: 1200px) {
      font-size: 14px;
      font-weight: bold;
    }
  }
}

.empty-survey-card {
  background-color: #f7f7f7;

  @media screen and(max-width: 767px) {
    margin: 1rem 0;
  }

  &:hover {
    cursor: pointer;
  }
}

.app-border {
  margin-bottom: 40px;
  padding: 10px 100px 20px;

  @media screen and (max-width: 767px) {
    padding: 10px 0px 20px;
  }
}

.newSettingsCard {
  margin-bottom: 40px;

  .newSettingsCard__Header {
    min-height: 55px;
    display: flex;
    align-items: center;
    background: #f3f5f7;
    border: 1px solid #d9d9d9;
    border-bottom: none;
    padding: 0 2rem;
    font-weight: 600;
    text-transform: capitalize;
  }

  .newSettingsCard__Body {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    padding: 1rem 2rem;
  }

  .newSettingsCard__SubDiv {
    margin-top: 10px;
  }
}

.systemFlagsTable {
  border: 1px solid #e6e5e5;
  background-color: transparent !important;
}

.menu-item-esp {
  width: 600px;

  @media screen and (max-width: 566px) {
    width: 300px;
  }
}

.page-content {
  border-radius: 4px;
  min-height: 93vh;
  padding: 0px;
  // margin-top: 7vh;

  @media screen and (max-width: 767px) {
    padding-left: 0px;
    padding-right: 0px;
  }

  @media screen and (max-width: 1260px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.q_page__mobile {
  @media screen and(max-width: 767px) {
    margin-bottom: 100px;
  }
}

.inputHeader {
  font-size: 13px;
  padding: 0px 0px 8px;
  display: flex;
  align-items: center;
  color: rgb(82, 80, 80);
  font-weight: bold;
}

.responsepage_tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.rt-noData {
  height: 100%;
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.pageTab_switcher {
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 767px) {
    justify-content: center;
  }
}

.pageTabs {
  margin-top: 10px;
}

.questions-content {
  padding: 0px;

  @media screen and (max-width: 1260px) {
    padding: 0px 0px;
  }

  @media screen and (max-width: 767px) {
    padding: 0px 0px;
  }
}

.subform-row {
  position: relative;

  &:hover {
    cursor: pointer;
  }
}

.row_closed {
  td {
    &:last-child {
      background: url('../images/svgs/caret-down.svg') no-repeat;
      background-size: contain;
      background-position: 100%;
    }
  }
}

.row_opened {
  td {
    &:last-child {
      background: url('../images/svgs/caret.svg') no-repeat;
      background-size: contain;
      background-position: 100%;
    }
  }
}

.subform-row__collapsible {
  &:hover {
    cursor: pointer;
  }
}

.subform-row__opened {
  background: rgba(0, 0, 0, 0.075);
}

.emodal_body {
  *padding: 0rem 1rem 1rem !important;

  @media screen and (max-width: 575px) {
    *padding: 0.5rem 0.5rem 1rem 0.5rem !important;
  }
}

.emodal_content {
  width: 40% !important;

  @media screen and (min-width: 1200px) {
    width: 30% !important;
  }

  @media screen and (max-width: 575px) {
    width: 90% !important;
  }
}

.modal_paper {
  border-radius: 10px !important;
  width: 100%;
}

.create_survey_emodal_body {
  @media screen and (max-width: 575px) {
    padding: 0.5rem 0.5rem 1rem 0.5rem !important;
  }
}

.create_survey_emodal_content {
  @media screen and (max-width: 575px) {
    width: 90% !important;
  }
}

.clickable {
  cursor: pointer;
}

.ant-btn-danger {
  color: #fff !important;
}

.unverified-msg {
  color: #6f6f6f !important;
  padding: 0.3rem 2rem;
  top: 0.1rem;
  text-align: center;
  position: absolute;
  z-index: 2000;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  margin-top: 0.5em;

  p {
    margin-bottom: 0;
  }

  .verify_link {
    color: #721c24;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.subform__report_card {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  margin-bottom: 35px;
  min-height: 200px;
  overflow-x: auto;

  .subform__report_card__body {
    padding: 0rem 2rem 0.8rem 2rem;

    @media screen and (max-width: 575px) {
      padding: 2rem 0.5rem 0.8rem 0.5rem;
    }
  }

  .subform__report_card__header {
    background: #e2e9f5;
    padding: 2rem;

    @media screen and (max-width: 575px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

.report_card {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  margin-bottom: 35px;
  min-height: 200px;
  padding: 2rem 2rem 0.8rem 2rem;
  overflow-x: auto;

  @media screen and (max-width: 575px) {
    padding: 2rem 1rem 0.8rem 1rem;
  }

  .td__question {
    width: 45%;
  }

  .td__count {
    text-align: right;
    width: 10%;
  }

  .td__percent {
    text-align: right;
    width: 10%;
  }

  .td__graph {
    width: 35%;
  }
}

.report_card__que {
  font-weight: bold;
  margin-bottom: 0.2rem;
}

.report_card__res-count {
  font-size: 0.8rem;

  color: #6f6f6f;
  margin-right: 0.5rem;
}

.report_card__skipped {
  color: #f51b1b;
  font-size: 0.8rem;
}

.report_card__th {
  border-top: 0;
  border-bottom: 0;
  color: #6f6f6f;
  // width: (100%)/4;
}

.th__question {
  width: 45%;
}

.th__count {
  text-align: right;
  width: 10%;
}

.th__percent {
  text-align: right;
  width: 10%;
}

.th__graph {
  width: 35%;
}

.no-font {
  color: transparent;
}

.table td,
.table th {
  vertical-align: middle !important;
}

.table th {
  border: none;
}

.progress {
  height: 8px;
}

.form-error {
  border: 1px solid #f51b1b;
}

.input-counter {
  width: 100%;
  font-size: 0.7rem;
  text-align: right;
}

.text-muted {
  color: rgba(82, 80, 80, 0.7) !important;
}

.settings-section-space {
  margin-bottom: 2rem;
}

.collaborators_list {
  background-color: #e6f5fe;

  .active-collaborator {
    color: #2570cb;
  }

  .collaborator_delete {
    cursor: pointer;
    padding: 0.6rem;
  }

  thead {
    background: transparent;

    th {
      background: white;
    }
  }
}

.custom-tooltip_width {
  font-size: 12px !important;
  max-width: 300px;
  word-wrap: break-word !important;
  pointer-events: auto !important;
  z-index: 3000;

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

.custom-tooltip_menu {
  @extend .custom-tooltip_width;
  max-width: 100%;
  z-index: 9999999999999;
}

.orangeBtn {
  background: $color-secondary;
  color: $color-white;
  border: 1px solid $color-secondary;

  &:hover {
    color: $color-secondary;
    border: 1px solid $color-secondary;
    background: $color-white;
  }
}

.orangeBtn_wrapper {
  background: $color-secondary;
  color: $color-white;

  // border: 1px solid $color-secondary;
  &:hover {
    // color: $color-secondary;
    border: 1px solid $color-white;
    // background: $color-white;
    background: $color-secondary;
    color: $color-white;
  }
}

.form_card_container {
  opacity: 0;
  visibility: hidden;
}

.form_card {
  background: $color-white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  min-height: 125px;
  padding: 1rem 1.1rem 0.5rem;
  transition: all 0.3s ease;

  @media screen and (min-width: 1200px) {
    min-height: 125px;
  }

  @media screen and (min-width: 1250px) {
    min-height: 125px;
  }

  &:hover {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    transform: scale(1.02);
    transform: translateY(-5px);
  }

  .form_card__header {
    display: flex;
    flex-direction: column;

    .form_card__headerMain {
      flex: 1.2;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        color: black;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 0;
        width: 100%;
      }

      h3 {
        color: black;
        font-size: 0.875rem;
        font-weight: bold;
        margin-bottom: 0;
        width: 100%;
        text-transform: capitalize;
      }
    }

    .form_card__headerSub {
      color: $color-muted;
      margin-top: -5px;

      span {
        font-size: 11px;
      }
    }
  }

  .form_card__body {
    flex: 2;
    font-size: 12px;
    margin-top: 0.5rem;

    .form_card__bodyDiv {
      display: flex;
      color: $color-black;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    .form_card__bodyDivRes {
      color: $color-black;
      display: flex;

      div {
        margin-right: 0.2rem;
      }

      span {
        font-size: 12px;
      }
    }
  }

  .formCard__AccessCode {

    // color: #4568bf;
    span {
      font-size: 12px;
    }
  }

  .card_state {
    text-transform: uppercase;
    margin-left: auto;
    // font-size: 12px !important;
  }
}

.form_card_blank {
  background: $color-white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);
  display: flex;
  color: $color-primary;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  min-height: inherit;
  // height: 100%;
  min-height: 150px;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    transform: scale(1.1);
    // transform: translateY(10px);
  }
}

.template_card {
  background: $color-white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);
  display: flex;
  font-weight: 700;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  transition: all 0.3s ease;
  min-height: 150px;
  // height: 100%;

  &:hover {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    transform: scale(1.04);
  }

  .template_card--header {
    display: flex;
    flex: 3;
    align-items: center;
    padding: 15px;
    padding-bottom: 10px;
    width: 100%;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }

    @media screen and (max-width: 991px) {
      flex-direction: row;
    }
  }

  .template_card--header_left {
    margin-right: 5px;
  }

  .template_card--header_right {
    font-size: 14px;
    color: black;
  }

  .template_card--footer {
    background-color: #f9f9f9;
    padding: 10px;
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    flex: 2;
  }
}

.react-phone-number-input__icon {
  height: 100%;
  border: none;
}

.phone_error {
  color: red;
  font-size: 10px;
  position: absolute;
  bottom: -18px;
  left: 0;
  width: 100%;
}

.subform__selector {
  position: relative;

  .subform__selector-input-div {
    width: 100%;
  }

  .subform__selector-btn-div {
    text-align: right;
    width: 50px;
  }

  .subform__selector-btn {
    cursor: pointer;
    background: transparent;
    padding: 0.5rem;
    position: absolute;
    top: 0;
    right: 0;

    &:focus {
      outline: none;
    }
  }
}

.rrui__expandable--overlay {
  z-index: 100;
}

.extraReportLinks {
  background: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  color: #000000;
  cursor: pointer;
  font-size: 0.7rem;
  // font-weight: bold;
  border-radius: 2px;
  margin-right: 1rem;
  padding: 0.5rem;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    color: #000000;
  }
}

.targetometer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  p {
    margin-bottom: 0;
  }

  .targetometerBar {
    background: grey;
    border-radius: 5px;
    height: 10px;
    width: 100%;
  }

  .targetometerBar_indicators {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
  }
}

.item_list {
  border: 1px solid #e7e7e9;
  font-size: 0.8rem;
  // height: 200px;
  overflow-y: auto;
  // margin-bottom: 20px;
  margin-bottom: 5px;
  z-index: 3500;
}

.item_list__item {
  display: flex;
  justify-content: space-between;
  padding: 0rem 0.8rem 0rem 1.4rem;
  margin-bottom: 0.3rem;
  height: 35px;
  background: url('../images/svgs/six.svg') no-repeat;
  background-size: 10px;
  background-position: 1%;

  .item_list__item--options {
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .rule-defined {
    font-size: 0.55rem;
    font-weight: 600;
    line-height: 3;
    color: #ffa725;
  }

  &:hover {
    .item_list__item--options-span {
      visibility: visible;
      display: flex;
      // align-items: center;
    }

    .rule-defined {
      display: none;
    }
  }

  .ant-radio-wrapper,
  .ant-checkbox-wrapper {
    padding-top: 7.5px;
    margin-right: 8px;
  }
}

.item_list__item--options-span {
  visibility: hidden;
  display: none;
  align-items: center;

  i {
    margin: 0 0.25rem;
  }
}

.item_list__input {
  width: 100%;
  overflow: hidden;
  // padding-top: 4.5px;
  padding-top: 7.5px;
  height: 30px;
  font-size: 14px;

  &:disabled {
    background: transparent;
    border: none;
  }
}

.dragging-helper-class {
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2),
    0 -5px 5px -5px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.8);
  cursor: row-resize;
}

.item_list span:hover {
  background-color: #f4f6f8;
  cursor: pointer;
}

.recaptchaDiv {
  width: 100%;
  margin-top: 1rem;
  z-index: 5000;

  div {
    width: 100% !important;
  }
}

.q_section {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  width: 95%;

  div {
    max-width: 70%;
    min-width: 40%;

    span {
      font-size: 13px;
      font-weight: bold;
      min-width: 100%;
      word-wrap: break-word;
    }
  }

  &::before {
    background: #cacaca;
    content: '';
    height: 2px;
    transition: 0.5s;
    width: 100%;
  }

  &::after {
    background: #cacaca;
    content: '';
    height: 2px;
    transition: 0.5s;
    width: 65%;
  }

  &::before {
    width: 90px;
    margin-right: 0.5rem;
  }

  &::after {
    margin-left: 0.5rem;
  }

  img {
    cursor: pointer;
    width: 20px;
  }
}

.resizeInput {
  width: 80%;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.dependencies-container {
  padding: 20px;
  border-radius: 4px;
  margin: 10px 0;
  border: 2px solid #e2e5ea;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 13px;
    top: -12px;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #e2e5ea;
  }

  &::after {
    content: '';
    position: absolute;
    left: 15px;
    top: -10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
  }

  @media screen and (max-width: 768px) {
    &::before {
      left: 13px;
    }

    &::after {
      left: 15px;
    }
  }
}

.blueBtn {
  background-color: $color-primary !important;
  border-radius: 3px;

  &:hover {
    background-color: #073255 !important;
  }

  &:disabled {
    background-color: rgba($color-primary, 0.5) !important;
  }
}

.stats-table {
  tr {
    td {
      border: none;
    }

    &:nth-of-type(even) {
      background-color: #f8fafc;
    }
  }
}

.agents-table {
  tr {
    td {
      border: none;
    }

    &:nth-child(4n + 1) {
      background-color: #f8fafc;
    }

    &:hover {
      background-color: #cee1f5;
    }
  }
}

.pageHeaderDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;

  .pageHeaderDiv__leftDiv {
    display: flex;
  }

  .pageHeaderDiv__rightDiv {
    display: flex;
    flex-direction: column;
  }
}

.pageHeaderDiv__rightDiv_btns {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dadada;
  background: white;
  border-radius: 3px;
  margin-right: 0.2rem;
  padding: 0.3rem 0.7rem;
  transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:focus {
    outline: none;
  }

  img {
    margin-right: 0.4rem;
    width: 15px;
  }

  span {
    font-size: 0.88rem;
  }

  &:hover {
    background-color: #cee1f5;
    cursor: pointer;
  }
}

.noBtn {
  background: transparent;
  border: none;
}

.tabContainer {
  margin: 2rem 0;
  padding-bottom: 10.5px;
  border-bottom: 1px solid #d0d8e1;
  font-size: 15px;
  font-weight: 600;

  .tabLink {
    cursor: pointer;
    padding: 0 26.5px;
  }

  .activeTabStyle {
    color: #2447b3;
    border-bottom: 3px solid #2d82dc;
    padding-bottom: 0.7rem;
  }
}

.savedChartsMenu {
  position: absolute;
  top: 8px;
  right: 30px;
}

.custom-row {
  max-height: 200px;
}

.chart-bg {
  background: white;
  padding: 0.5rem;
  width: 100%;
  height: 100%;

  .singleChartOption__Container {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h6 {
      padding-left: 0.5rem;
      color: black;
      font-weight: bold;
      margin-bottom: 0;
      width: 75%;
    }

    div {
      display: flex;
    }

    .singleChartOption__btns {
      width: 12px;
      height: 12px;
      margin-right: 0.4rem;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.page-nav {
  &:hover {
    .pageEditIcon {
      visibility: visible;
    }
  }
}

.pageInputContainer {
  box-shadow: 0px 3px 6px rgba(#000000, 0.16);
  position: absolute;
  bottom: -128px;
  right: 0px;
  padding: 0.5rem;
  z-index: 3000;
  min-width: 340px;

  background: white;

  .pageInputForm {
    // height: inherit;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;

    div {
      margin-top: 0.5rem;
    }
  }

  label {
    align-self: flex-start;
  }
}

.page-nav__Info {
  display: flex;
  justify-content: space-between;
}

.auditContainer {
  padding: 1.5rem 0;

  .auditContainer__Body {
    max-height: 680px;
    overflow: auto;
  }

  .auditContainer__Header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    span {
      &:first-child {
        text-transform: capitalize;
      }

      &:last-child {
        color: #2d82dc;
        cursor: pointer;
        transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

        &:hover {
          font-weight: bold;
        }
      }
    }
  }

  .auditContainer__audit {
    background: white;
    border: 1px solid #e8eef3;
    // border-radius: 10px;
    padding: 1rem;
    margin-bottom: 1rem;
  }
}

.flagCard__Container {
  display: flex;
  padding: 0 1rem;
  margin: 1rem 0 1.5rem;

  .flagCard_active {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0),
      0 10px 10px rgba($color-primary, 0.22);
  }

  .flagCard {
    background: white;
    margin-right: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 130px;
    min-height: 65px;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    p {
      margin: 0;

      &:first-child {
        font-size: 1.3rem;
      }

      &:last-child {
        font-size: 0.8rem;
      }
    }

    &:hover {
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0),
        0 10px 10px rgba($color-primary, 0.22);
      cursor: pointer;
    }
  }
}

.tabularSearch__Input {
  background: url('../images/svgs/search.svg') no-repeat;
  background-size: 12px;
  background-position: 2% 60%;
  background-color: white;
  margin: 0 0.8rem;
  min-width: 300px;
  padding: 0 1.3rem 0;

  &::placeholder {
    font-size: 0.75rem;
  }
}

.accessControlCollapsible {
  transition: 0.3s ease-in;
  padding: 0.4rem;
  background-color: #f4f6f8;
  color: #7d97b5;

  &:hover {
    cursor: pointer;
  }
}

.accessControlCollapsible__open {
  background: url('../images/svgs/arrow-up.svg') no-repeat;
  background-color: #f4f6f8;
  background-size: 10px;
  background-position: 98%;
}

.accessControlCollapsible__closed {
  background: url('../images/svgs/arrow-down.svg') no-repeat;
  background-color: #f4f6f8;
  background-size: 10px;
  background-position: 98%;
}

.accessControlCollapsible__list {
  height: 200px;
  overflow: auto;
  padding: 0.5rem;
  border: 1px solid #ededed;

  .accessControlCollapsible__listInfo {
    font-weight: 600;
    color: #000000;
    margin-bottom: 0.4rem;
    margin-top: -0.4rem;
  }
}

.insyt-loader {
  border: 2px solid #125388;
  /* Light grey */
  border-top: 2px solid transparent;
  /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.img-viewer {
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  visibility: hidden;
  height: 100vh;
  cursor: pointer;
  // transition: 0.3s ease-in;

  .img-viewer__Inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .img-viewer__Close {
    position: absolute;
    top: 20px;
    right: 50px;
    cursor: pointer;
  }

  .img-viewer__Image {
    background: white;
    max-height: 90vh;
  }
}

.fingers_UI--detailedView {
  background: white;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem;
  overflow-x: auto;

  .finger_UI {
    padding: 0.1rem;
    border: 2px solid rgba(85, 85, 85, 0.164);
    margin-right: 0.5rem;
    margin-bottom: 1rem;
    text-align: center;
    width: 90px;

    img {
      width: 50px;
    }

    p {
      margin-top: 1rem;
    }
  }
}

.fingers_UI {
  background: white;
  display: flex;
  flex-wrap: wrap;
  max-width: 822px;
  padding: 1rem 1rem 0 1rem;

  .finger_UI {
    padding: 1rem;
    border: 2px solid rgba(85, 85, 85, 0.164);
    margin-right: 0.5rem;
    margin-bottom: 1rem;
    width: 150px;
    text-align: center;

    img {
      width: 100px;
    }

    p {
      margin-top: 1rem;
    }
  }
}

.img-viewer-show {
  background: rgba(#000000, 0.76);
  z-index: 10000;
  visibility: visible;
}

.AppInfoCard {
  position: fixed;
  min-width: 400px;
  // min-height: 150px;
  bottom: 3%;
  right: 5%;
  z-index: 1000;

  background: white;

  .AppInfoCard__Header {
    background: $color-primary;
    padding: 1rem;
    color: white;
    font-weight: bold;
  }

  .AppInfoCard__Content {
    padding: 1rem;
  }
}

.ant-breadcrumb-separator {
  margin: 0 4px;
}

.slide_up {
  animation: slide_up 0.4s ease-in-out;
  // transition: all 0.5s ease-in-out;
}

@keyframes slide_up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide_down {
  animation: slide_down 0.4s ease;
}

@keyframes slide_down {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.accept_other_tree {
  padding: 0.8rem 0.3rem;
  border-bottom: 1px solid rgba(116, 118, 120, 0.1);

  .switch {
    float: right;
  }
}

.billingInfoModal {
  width: 65% !important;

  .ant-modal-footer {
    background-color: #edf2f6;
  }

  @media screen and (min-width: 1200px) {
    width: 680px !important;
  }

  .billingInfoModal__Body {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 2rem;
    color: $color-primary;
    height: 300px;

    .billingInfoModal__BodyRight {
      flex: 1;
      border-left: 1px solid rgba($color-primary, 0.2);
      padding: 1rem 1.8rem;

      .billingInfoModal__BodyRightHeader {
        margin-bottom: 1rem;
      }

      .billingInfoModal__BodyRightList {
        p {
          margin-bottom: 0.5rem;
          display: flex;
        }
      }
    }

    .billingInfoModal__BodyLeft {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // flex: 1;

      .price {
        font-size: 2.5rem;
        margin: 1.4rem 0;
      }

      div {
        max-width: 95%;
      }

      button {
        margin-bottom: 1.4rem;
        max-width: 95%;
      }
    }
  }
}

.mockCardUI {
  display: flex;
  border: 1px solid #dae7f7;
  // background: #f3f8fe;
  max-width: 350px;
  justify-content: space-around;
  align-items: center;
  margin-top: 0.75rem;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0),
      0 10px 10px rgba($color-primary, 0.22);
  }

  .mockCardUI__Top {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    color: #707070;
  }

  .mockCardUI__Bottom {
    background-color: #f4f7fa;
    padding: 0.5rem 1rem;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .mockCardUI__BottomLower {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.responsesTable {
  .ant-table-footer {
    padding: 0 !important;
  }

  .customFooter {
    background-color: #e62f2f;
    color: white;
    text-align: center;
    padding: 1rem;

    .customFooter__LINK {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.tableLink {
  color: $color-hover-blue;
  transition: 0.2s ease;

  &:hover {
    cursor: pointer;
    color: $color-secondary;
  }
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(12, 42, 114, 0.04);
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: transparent;
}

.customAlertBox {
  background-color: #f5f5f5;
  padding: 0.4rem 0.8rem;

  h3 {
    font-weight: 600;
  }
}

.BankDepDetails {
  display: flex;
  flex: 1 3;
  justify-content: space-around;
  background-color: #f8f8f8;
  border-radius: 2px solid transparent;
  margin-top: 2rem;
  padding: 1.4rem;
  color: #000000;

  img {
    flex: 1;
    width: 55px;
    height: 55px;
  }

  div {
    flex: 3;
  }
}

.mini-receipt-table {
  color: #7c7c7c;
  font-size: 0.8rem;
  max-width: 240px;
}

#suspended___account {
  // display: flex;
  // justify-content: center;
  // align-content: center;
  // justify-items: center;
  // // flex-direction: column;
  margin: 0 auto;
  margin-top: 11%;
  text-align: center;
  background: #fff;
  width: 37%;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);

  .top {
    padding: 50px 100px;
    color: red;
    font-weight: 500;
    font-size: 24px;
  }

  .bottom {
    color: #000000;
    background: #f8f8f8;
    padding: 50px 120px;

    &>div {
      margin-bottom: 40px;
    }
  }
}

.ant-avatar-images {
  width: auto !important;
}

.ant-avatar-images>img {
  width: auto !important;
}

#subs__card {
  background: rgba(5, 84, 141, 0.05);
  border: 1px solid rgba(5, 84, 141, 0.1);
  padding: 20px;
  margin-top: 20px;
  min-width: 400px;
  border-radius: 3px;

  .plan {
    color: #000;
    font-size: 1.5rem;
  }
}

.skip__select__question {
  white-space: normal !important;
  text-overflow: ellipsis;
  border-bottom: 1px solid #eff4f8;
}

.rc-slider-mark-text {
  width: 40px;
}

.dx-g-bs4-table-container {
  background: white;
  height: calc(100vh - 350px) !important;
}

.responses-custom-table {
  tbody tr:nth-child(odd) {
    background-color: #f3f3f3;
  }

  tbody tr {
    &:hover {
      cursor: pointer;
      background-color: #f0f0f0;
    }
  }
}

.es-slider {
  width: 300px !important;
  position: relative;

  .slick-list {
    background: url('../images/svgs/Bg_Tab.svg') no-repeat;
    background-size: contain;
    padding: 40px 8px 15px 8px;
  }

  .slick-prev-es {
    left: -25px;
    position: absolute;
  }

  .slick-next-es {
    right: -25px;
    position: absolute;
  }

  .slick-next-es,
  .slick-prev-es {
    top: 50%;
    font-size: 24px;
    color: #b7b7b7;
  }
}

.access__code__select {
  width: 100%;
  border: 1px solid #E3E3E3;

  .ant-select-selection {
    border-radius: 0px;
    border: 0px;
    min-height: 33px;

    &:focus {
      box-shadow: none;
    }
  }

  .ant-select-selection__choice {
    border: 0px;
    color: #6F6F6F;
    background-color: #E4F4FD;
  }
}

.select___builder {
  width: 50%;

  .ant-select-selection {
    background: #f5f6f8;
    border-radius: 0px;
    border: 0px;
  }
}

#no__review__data {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 40px #00000012;
  width: 70%;
  margin: 0 auto;
  margin-top: 10rem;

  .ant-col-lg-14 {
    padding: 3rem 0rem 4rem 5rem;

    b {
      color: #000;
      font-size: 12px;
      display: block;
      margin-bottom: 7px;
    }

    h3 {
      color: #000;
    }
  }

  .ant-col-lg-10 {
    padding: 35px;

    .img {
      text-align: center;
      margin-bottom: 30px;

      img {
        width: 80%;
      }
    }
  }
}