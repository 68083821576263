.optionTree_branch {
  background-color: #ffffff;
  height: 400px;
  min-width: 25%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 3px rgba($color-primary, 0.12),
    0 1px 2px rgba($color-primary, 0.24);
  padding-bottom: 50px;
  position: relative;
}

.optionTree_branch_overflow {
  height: 280px;
  overflow: hidden;

  input {
    &:hover {
      cursor: pointer;
    }
  }
}

.deleteIcon {
  position: absolute;
  right: 5%;
  top: 25%;
  padding: 0.1rem;
  background: pink;
  cursor: pointer;
  z-index: 3000;
}

.optionTree {
  display: flex;
  position: relative;
  margin-bottom: 50px;
  background: #eceff1;
  overflow: auto;

  .loader {
    background: rgba($color-primary, 0.2);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
}

.removeBgonLast {
  background-image: none !important;
}

.optionTree_leaf {
  border-bottom: 1px solid rgba(116, 118, 120, 0.1);
  position: relative;
  background: url('../../images/svgs/arrow-right.svg') no-repeat;
  background-size: 10px;
  background-position: 95% 50%;
  cursor: pointer;
  width: 100%;

  input {
    background: transparent;
    padding: 0.8rem 0.3rem;
    border: 0;
    margin: 0;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  .textarea__Btns {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10%;

    div {
      background-color: #e4e4e4;
      align-items: center;
      justify-content: center;
      display: flex;
      // color: rgb(190, 190, 190);
      font-size: 0.65rem;
      width: 50%;

      &:last-child {
        border-left: 1px solid #f5f6f8;
      }

      &:hover {
        background-color: #f5f6f8;
      }
    }
  }

  textarea {
    background: transparent;
    padding: 0.4rem;
    border: 0;
    margin: 0;
    margin-bottom: -10px;
    width: 100%;
    height: 90%;
    background-color: #ffffff;
    font-size: 0.8rem;

    &:focus {
      outline: none;
    }
  }
}

.optionTree_leaf__input {
  display: flex;
  position: absolute;
  bottom: 0;
  background: white;
  // border-right: 1px solid rgba(233, 233, 233, 0.7);
  width: 100%;
  overflow: hidden;

  .switchToList {
    display: flex;
    align-items: center;
    background-color: #f5f6f8;
    padding-left: 0.3rem;
    width: 40px;

    svg {
      color: rgba(0, 0, 0, 0.3) !important;
    }

    &:hover {
      cursor: pointer;
      background: #e4e4e4;
    }
  }
  input {
    background: #f5f6f8;
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: rgba(0, 0, 0, 0.3);
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: rgba(0, 0, 0, 0.3);
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: rgba(0, 0, 0, 0.3);
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: rgba(0, 0, 0, 0.3);
    }

    &:focus {
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(0, 0, 0, 0.8);
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(0, 0, 0, 0.8);
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(0, 0, 0, 0.8);
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(0, 0, 0, 0.8);
      }
    }
  }

  textarea {
    background: #f5f6f8;
  }
}

.optionTree_leaf__selected {
  background: url('../../images/svgs/arrow-right-white.svg') no-repeat;
  background-size: 10px;
  background-position: 95% 50%;
  background-color: #2499ef;
}

.optionTree_leaf__moreActions {
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0px;
  z-index: 3000;
  // border: 1px solid #aeaeae;

  span {
    border: 1px solid #aeaeae;
    cursor: pointer;
    padding: 0.2rem;
    width: 50%;

    &:last-child {
      border-left: 0;
    }

    img {
      width: 14px;
    }
  }
}

.optionTree_ErrorMsg {
  bottom: -22px;
  color: red;
  font-size: 0.8rem;
  position: absolute;
}

.optionTree__Header {
  background-color: #f9f9f9;
  border-bottom: 1px solid rgba(116, 118, 120, 0.1);
  position: relative;
  cursor: pointer;
  width: 100%;
  display: flex;
  // justify-content: spa

  input {
    background: transparent;
    color: #2499ef;
    font-weight: bold;
    padding: 0.8rem 0.3rem;
    border: 0;
    margin: 0;
    width: 100%;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      text-align: center;
      font-size: 0.69rem;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      text-align: center;
      font-size: 0.69rem;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      text-align: center;
      font-size: 0.69rem;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      text-align: center;
      font-size: 0.69rem;
    }
    &:focus {
      outline: none;
    }
  }
}

.optionTree__Header__moreActions {
  align-items: center;
  display: flex;
  justify-content: center;
  // position: absolute;
  // right: 0px;
  // top: 0;
  z-index: 3000;
  // border: 1px solid #aeaeae;

  span {
    // border: 1px solid #aeaeae;
    cursor: pointer;
    // padding: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;

    &:last-child {
      border-left: 0;
    }

    img {
      width: 14px;
    }
  }
}

// new tree styles
.es-tree-upload-btn {
  text-align: right;
  [type='file'] {
    height: 0;
    overflow: hidden;
    width: 0;
  }

  [type='file'] + label {
    background: $color-primary;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: inherit;
    font-weight: 600;
    // margin-bottom: 1rem;
    outline: none;
    padding: 0.5rem 50px;
    position: relative;
    transition: all 0.3s;
    vertical-align: middle;

    &:hover {
      background-color: darken($color-primary, 10%);
    }
  }
}

.es-tree {
  display: flex;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 50px;
  border: 1px solid #eceff1;
  background: #eceff1;
  overflow: auto;
  width: 100%;
  min-height: 400px;

  .es-tree-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(#137b85, 0.55);
    z-index: 2;
    .loader {
      display: inline-block;
      width: 50px;
      height: 50px;
      border: 3px solid rgba(255, 255, 255, 0.3);
      border-radius: 50%;
      border-top-color: #fff;
      animation: spin 1s ease-in-out infinite;
      -webkit-animation: spin 1s ease-in-out infinite;
    }
    @keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
  }
  .es-tree--empty-instructions {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 0.8rem;
      color: #888888;
      text-align: center;
    }
  }
  .es-branch {
    background-color: #fff;
    height: 400px;
    min-width: 25%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 3px rgba(5, 84, 141, 0.12),
      0 1px 2px rgba(5, 84, 141, 0.24);
    padding-bottom: 40px;
    position: relative;
    .es-branch_header {
      background-color: #f9f9f9;
      border-bottom: 1px solid rgba(116, 118, 120, 0.1);
      position: relative;
      cursor: pointer;
      width: 100%;
      height: 45px;
      display: flex;
      input {
        width: 100%;
        color: #2499ef;
        font-weight: 600;
        border: none;
        padding-left: 0.3rem;
        &::placeholder {
          font-weight: 600;
          text-align: center;
        }
      }
    }
    .es-branch_body {
      position: relative;
      overflow: auto;
      width: 100%;
      height: 100%;
    }
    .es-branch_footer {
      display: flex;
      position: absolute;
      bottom: 0;
      background: #f5f6f8;
      width: 100%;
      overflow: hidden;
    }
    .es-leaf_btn {
      height: inherit;
      border: none;
      background-color: transparent;
      width: 40px;
      // i {
      //   // color: #137b85;
      // }
      &:focus {
        outline: none;
        border: 1px solid #474747;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .es-leaf {
    border-bottom: 1px solid rgba(116, 118, 120, 0.1);
    position: relative;
    // background: url(/static/media/arrow-right.f402831b.svg) no-repeat;
    background-size: 10px;
    background-position: 95% 50%;
    width: 100%;
    height: 40px;
    .es-leaf_confirm {
      display: flex;
      font-size: 0.85rem;
      padding-left: 0.5rem;
      align-items: center;
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: #eceff1;
      span {
        margin: 0 0.2rem;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
  .es-leaf_active {
    background-color: #2499ef;
    input {
      color: white;
    }
  }
  .es-leaf_input {
    background: transparent;
    cursor: pointer;
    padding: 0.8rem 0rem;
    padding-left: 0.2rem;
    border: 0;
    margin: 0;
    width: calc(100% - 40px);
    height: 100%;
    &:focus {
      outline: none;
      border: 1px solid #474747;
    }
  }
  .es-leaf_textarea {
    height: calc(400px - 120px);
    width: 100%;
    resize: none;
    &:focus {
      outline: none;
      border: 1px solid #474747;
    }
  }
}
