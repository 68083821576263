$color-primary: #05548d;
$color-hover-blue: #065d9b;
$color-secondary: #e3611c;
$color-secondary-rgb: rgb(227, 97, 28);
$color-danger: #ad0101;
$color-white: #fff;
$color-grey: #e6e5e5;
$color-black: #000000;
$color-muted: #858585;
$color-dark-grey: #737373;
$sidebar-color-primary: #05548d;
$sidebar-color-secondary: #023a63;
