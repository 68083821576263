.landingPage {
  background: white;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 1rem 40px;

    a {
      color: #104b9d;
      margin-bottom: 0.5rem;
    }
  }

  .hero {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 12rem 0 0;
    margin-bottom: -8rem;

    @media screen and (max-width: 767px) {
      padding: 4rem 0 0 !important;
    }

    h2 {
      font-size: 48px;
    }

    p {
      font-size: 16px;
      margin: 1rem auto;
      max-width: 80%;
    }

    div {
      display: block;

      @media screen and (max-width: 767px) {
        a {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .section-header {
    margin-bottom: 4rem;
    h2 {
      font-family: Poppins, sans-serif;
    }
    p {
      font-family: Muli, sans-serif;
      font-size: 16px;
      font-weight: 400;
      max-width: 65%;
      margin: 0 auto;
      @media screen and (max-width: 767px) {
        max-width: 100%;
      }
    }
  }

  .section-1 {
    background: url('../../images/assets/section1bg.svg') no-repeat;
    background-position: 20% 70%;
    background-size: cover;
    padding-top: 20rem;
    padding-bottom: 20rem;
    margin-top: -1rem;
    margin-bottom: -15rem;
    @media screen and (max-width: 767px) {
      background: none !important;
      padding-top: 10rem;
      padding-bottom: 5rem;
      margin-bottom: 0;

      .trapezoid {
        background: none !important;
        padding-left: 0 !important;
      }

      .section-1-container {
        background: url('../../images/assets/section-1-row.svg') no-repeat;
        background-size: cover;
        background-position: 23%;
        padding-bottom: 1rem;
        padding-top: 1rem;
      }

      .section-1-details {
        margin-top: 0 !important;
        width: 100% !important;
        padding-left: 0 !important;

        h2 {
          padding-left: 2rem;
          color: white !important;
        }

        p {
          font-size: 12px !important;
          padding-left: 2rem;
        }
      }
    }

    .section-1-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: white;
    }

    .trapezoid {
      background: url('../../images/assets/section-1-row.svg') no-repeat;
      background-size: 90%;

      @media screen and (max-width: 991px) {
        background-position: 10% 10% !important;
        background-size: 100% !important;
        padding-left: 2rem;

        .section-1-details {
          h2 {
            padding-left: 2rem;
            font-size: 1.5rem !important;
            color: white !important;
          }

          p {
            font-size: 0.8rem !important;
            padding-left: 2rem;
          }
        }
      }
    }

    .section-1-details {
      margin-top: -3rem;
      width: 80%;
      padding-left: 1.5rem;
      p {
        font-size: 14px;
        font-family: Muli, sans-serif;
        font-weight: 300;
      }

      h2 {
        font-family: Poppins, sans-serif;
        font-weight: 500;
        color: white !important;

        @media screen and (max-width: 991px) {
          font-size: 28px !important;
        }
      }

      @media screen and (max-width: 991px) {
        padding-left: 0 !important;
        width: 100%;
      }
    }

    .section-1-imgs {
      position: relative;
      margin-top: -1.5rem;

      .pixel-phone-img {
        position: absolute;
        right: 2rem;
        bottom: -1.5rem;
        max-height: 250px;
      }

      // .all-in-one-pc {
      //   // max-width: ;
      // }
    }
  }

  .section-2 {
    padding-top: 10rem;
    padding-bottom: 10rem;

    @media screen and (max-width: 991px) {
      .section-2-info {
        p {
          width: 100% !important;
        }
      }
    }

    @media screen and (max-width: 767px) {
      padding-top: 5rem;
      padding-bottom: 5rem;

      .section-2-row-2 {
        background: none !important;

        img {
          margin-top: 0 !important;
          margin-bottom: 0 !important;
        }
      }

      .section-2-info {
        background: url('../../images/assets/section-2-bg.svg') no-repeat;
        background-position: 80%;
        background-size: cover;

        p {
          width: 100% !important;
        }
      }
    }
    .section-2-row-2 {
      background: url('../../images/assets/section-2-bg.svg') no-repeat;
      background-position: 80%;
      background-size: 90%;

      img {
        margin-top: 1.5rem;
        margin-bottom: -2.5rem;
      }

      .section-2-info {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        p {
          font-size: 1.05rem;
          font-family: Muli, sans-serif;
          font-weight: 400;
          width: 75%;
          line-height: 2.2rem;
        }
      }
    }
  }

  .section-3 {
    margin-bottom: 3rem;
    margin-top: 10rem;

    @media screen and (max-width: 767px) {
      margin-bottom: 4rem;
      margin-top: 4rem;

      .blue-phone {
        max-width: 200px;
      }

      .section-3-img {
        text-align: center;
      }

      .section-3-row {
        background: none !important;

        .section-3-info {
          background: url('../../images/assets/section-3-bg.svg') no-repeat;
          background-position: 40% 10%;
          background-size: cover;

          p {
            margin-right: 0;
            margin-top: 0rem !important;
            padding-bottom: 2rem;
            width: 100% !important;
          }
        }
      }
    }

    @media screen and (max-width: 1199px) {
      .section-3-row {
        background-position: 40% 20% !important;
        background-size: 100% !important;
      }
      .section-3-info {
        p {
          margin-right: -3rem;
          width: 100% !important;
        }
      }
    }

    .section-3-row {
      background: url('../../images/assets/section-3-bg.svg') no-repeat;
      background-position: 10% 10%;
      background-size: 65%;

      .section-3-info {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        p {
          margin-top: 2.5rem;
          color: white;
          font-family: Muli, sans-serif;
          font-size: 1rem;
          font-weight: 300;
          width: 75%;
          height: 80%;
          line-height: 2rem;
        }
      }
    }
  }

  .section-3-pricing {
    .getStartedBtn {
      border-radius: 3px;
      background-color: #2a72d7;
      text-align: center;
      width: 250px;
      height: 60px;
      color: white;
      border: none;
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      margin: 2rem 0;
      &:hover {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      }
    }
    .diagonal-parent {
      transform: skewY(10deg);
      width: 100%;
      min-height: 650px;
      background: #104b9d;
      display: flex;
      // align-items: center;
      justify-content: center;
      padding: 4rem 0;

      .diagonal-child {
        transform: skewY(-10deg);
        max-width: 650px;
      }
    }
  }

  .section-4,
  .section-5 {
    .section-header {
      margin-bottom: 2rem;
    }
    margin-bottom: 5rem;
    margin-top: 10rem;
  }

  .footer {
    background: #f3f4f8;
    padding: 2rem 0;

    p {
      color: #989b9c;
      font-family: Muli, sans-serif;
      font-size: 15px;
      margin-top: 0.8rem;
    }
  }

  .playStore-icon {
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover {
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.16);
    }
  }

  .btn-outline {
    background: white;
    color: #2a72d7 !important;
    cursor: pointer;
    border: 1px solid #2a72d7;
    border-radius: 3px;
    display: inline-block;
    padding: 0.85rem 1.2rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
    min-width: 200px;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover {
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.16);
    }
  }

  .btn-blue {
    @extend .btn-outline;
    background: #2a72d7;
    color: white !important;
    margin: 0 10px;
  }

  #animateMacbook {
    transition: transform 0.3s ease-out;
  }

  // #animateInfo {
  //   // opacity: 0.4;
  // }

  .slideIN {
    transform: translate(10px, 20px);
  }

  .fadeIn {
    opacity: 1;
  }
}
