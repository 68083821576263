.ReviewExercise-Modal {
  .ant-input-number{
    width: 100%;
  }
  .ant-form-item-control {
    line-height: 1;
  }
  .ant-form-item.add_more_response,
  .ant-form-item.additional_responses {
    margin-bottom: 0!important;
  }
  .options {
    color : rgba(0,0,0,0.89);
    display: flex;
    flex-direction: column;
    gap: 22px;
    .option-item {
      display: flex;
      align-items: start;
      gap: 8px;
    }
    .radius-control {
      max-width: 200px;
      margin-top: 12px;
      h6 {
        color : rgba(0,0,0,0.55);
        margin-bottom: 8px;
      }
    }
  }
  .settings-form-footer {
    display: flex;
    justify-content: end;
    padding-left: 37px;
    gap: 10px;
  }
}