.analyticTypeCard {
  align-items: center;
  background: #ffffff;
  box-shadow: 0 1px 3px rgba($color-primary, 0.12),
    0 1px 2px rgba($color-primary, 0.24);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  // padding: 1.8rem;
  margin-bottom: 2rem;
  min-height: 250px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0),
      0 10px 10px rgba($color-primary, 0.22);
    cursor: pointer;
  }

  img {
    width: 100px;
  }

  .analyticTypeCard__infoContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    .analyticTypeCard__title {
      color: $color-primary;
      font-weight: 300;
      letter-spacing: -0.5px;
      font-size: 1.5rem;
      text-transform: capitalize;
    }
    h3 {
      color: black;
      font-size: 1.25rem;
      font-weight: 400;
      margin-bottom: 0;
      width: 100%;
      text-transform: capitalize;
      text-align: center;
    }

    .analyticTypeCard__description {
      color: #595959;
      text-align: center;
      font-size: 0.875rem;
      line-height: 1.375rem;
      font-weight: normal;
    }
  }

  .analyticTypeCard__btn {
    border: 1px solid $color-primary;
    border-radius: 50px;
    color: $color-primary;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 900;
    padding: 0.6rem 0.8rem;
    text-transform: capitalize;
    transition: 0.5s ease;
    &:hover {
      background: $color-primary;
      color: #ffffff;
    }
  }
}

.analyticTypeConfig__questionsList {
  // box-shadow: inset 0 0 10px #000000;
  padding: 0.8rem;
  height: 400px;
  overflow-y: auto;
  width: 300px;
}

.analyticView__miniCharts {
  display: flex;
  width: 100%;

  .analyticView__miniChart_active {
    .highcharts-root {
      .highcharts-background {
        stroke: $color-secondary;
        stroke-width: 5px;
      }
    }
  }

  .analyticView__miniChart {
    margin-right: 0.5rem;
    .highcharts-container {
      height: 150px !important;
      // margin: 0;
      width: 150px !important;
    }
    svg {
      width: 150px;
      height: 150px;
    }
  }
}

.analyticTypeConfig__trendOvaTime {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.2rem;

  .paramsContainer {
    margin-bottom: 1rem;
    width: 100%;

    .paramsContainer__info {
      color: #000000;
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 0.3rem;
    }
  }

  .chartParam {
    cursor: pointer;
    min-width: 60%;
    padding: 0.5rem 1.5rem 0.5rem 0.5rem;
    background-color: #f4f6f8;
    color: #7d97b5;
    display: flex;
    align-items: center;
    width: 100%;
    transition: 0.3s ease-in;

    p {
      margin: 0;
      font-size: 15px;
    }
  }
}

.questionSelector {
  border: 1px solid #ededed;
  max-height: 200px;
  overflow-x: hidden;
  // margin: 0 0 0 0;
  padding: 0.8rem 0.8rem;

  .questionSelector__subform {
    margin-top: -0.55rem;
    p {
      font-size: 0.9rem;
      font-weight: bold;
    }
    div {
      margin-left: 0.5rem;
    }
  }

  .questionSelector__subform_tree {
    p {
      font-size: 0.9rem;
      font-weight: bold;
    }
    div {
      margin-left: 0.62rem;
    }
  }
}

.custom_chartBtn {
  background-color: #fff;
  cursor: pointer;
  font-size: 0.7rem;
  padding: 0.4rem;
}

.custom_chartBtnActive {
  background: #125388;
  color: #fff;
}

.descAnalysis__card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .descAnalysis__table {
    border: 2px solid red;
    // max-width: 70%;
    padding: 2rem;
  }

  .descAnalysis__tableRow {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;

    span {
      width: 200px;
    }
  }
}

.chartLabellingForm {
  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: #fff;
  width: 100%;
  // margin: 50px auto;
  padding: 2rem 0;

  input {
    margin-bottom: 1rem;
    width: 100%;

    @media screen and (max-width: 767px) {
      width: 90%;
    }
  }

  label {
    color: black;
  }
}

.analyticsCustomHeaders {
  p {
    font-size: 22px;
    margin-bottom: 0;
  }

  span {
    font-size: 13px;
  }
}

.reportTypeCard {
  @extend .analyticTypeCard;
  min-height: 305px;
  color: inherit;
}

.agentPerformance__card {
  height: 80vh;
  min-width: 100%;
  display: flex;
  overflow-y: auto;

  .agentPerformance__card_Sidebar {
    border-right: 1px solid #dadada;
    // width: 25%;
    // overflow-y: auto;

    .agentPerformance__card_SidebarItem {
      // border-bottom: 1px solid #7d97b5;
      padding: 0.4rem 0.8rem;
      display: flex;

      img {
        width: 30px;
        margin: 0 10px;
        align-items: center;
      }

      &:hover {
        cursor: pointer;
        background-color: #c9e3ff;
      }
    }
  }

  .agentPerformance__card_MainArea {
    width: 100%;

    .agentPerformance__cardchart {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }

    .agentPerformance__cardchartbg {
      background: #f3f5f7;
      max-height: 430px;
      padding: 0.8rem;
    }

    .agentPerformance__cardMeta {
      height: calc(100% - 450px);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .metaBox {
        min-width: 40%;
        div {
          border: 1px solid rgba(black, 0.2);
          display: flex;
          justify-content: space-between;
          padding: 0.5rem;
        }

        button {
          margin: 20px 10%;
          cursor: pointer;
          border-radius: 0;
          border: none;
          padding: 0.6rem 0;
          color: white;
          background: #2447b3;
          width: 80%;
          transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

          &:hover {
            background: #05548d;
          }
        }
      }

      .agentPerformance__statusToggler {
        min-width: 30%;
        margin-top: 1rem;
      }
    }
  }
}

$md-radio-checked-color: #125388;
$md-radio-border-color: rgba(0, 0, 0, 0.54);
$md-radio-size: 15px;
$md-radio-checked-size: 7.5px;
$md-radio-ripple-size: 10px;

@keyframes ripple {
  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }
  50% {
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0);
  }
}

.md-radio {
  margin: 0.6rem 0;

  &.md-radio-inline {
    display: inline-block;
  }

  input[type='radio'] {
    display: none;
    &:checked + label:before {
      border-color: $md-radio-checked-color;
      animation: ripple 0.2s linear forwards;
    }
    &:checked + label:after {
      transform: scale(1);
    }
  }

  label {
    display: inline-block;
    // height: $md-radio-size;
    position: relative;
    padding: 0 ($md-radio-size + 10px);
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: text-top;
    &:before,
    &:after {
      position: absolute;
      content: '';
      border-radius: 50%;
      transition: all 0.3s ease;
      transition-property: transform, border-color;
    }
    &:before {
      left: 0;
      top: 20%;
      width: $md-radio-size;
      height: $md-radio-size;
      border: 2px solid $md-radio-border-color;
    }
    &:after {
      top: 34%;
      left: ($md-radio-size / 2 - $md-radio-checked-size / 2) + 0.25px;
      width: $md-radio-checked-size;
      height: $md-radio-checked-size;
      transform: scale(0);
      background: $md-radio-checked-color;
    }
  }
}
