.topNavbar {
  background-color: #f3f5f7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: inherit;
  padding: 0.5rem 30px;
  .topNavbar__left {
    p {
      margin-bottom: -0.41rem;
      color: black;
      font-size: 20px;
    }
  }
  .topNavbar__right {
    display: flex;
    justify-content: space-evenly;
  }

  .ant-breadcrumb-link {
    a:hover {
      color: $color-secondary !important;
    }
  }
}
