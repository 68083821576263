.agentsCard {
  position: absolute;
  top: 100px;
  right: 50px;
  background: #ffffff;
  width: 300px;
  height: 75%;
  overflow: hidden;
  z-index: 1;
  transition: 0.5s ease-in;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .agentsCard__Tab {
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: center;

    .active {
      background: #ffffff !important;
      font-weight: bold;

      p {
        color: $color-primary !important;
      }
    }

    .inactive {
      background: #e0e2e4;

      p {
        color: #6e6f72;
      }
    }

    div {
      cursor: pointer;
      height: inherit;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        // color: white;
        font-size: 0.85rem;
      }
    }
  }

  .agentsCard_item {
    align-items: center;
    display: flex;
    padding: 0.6rem;
    cursor: pointer;
  }

  .agentsCard_item__selected {
    background: #d1e7ff;
  }

  .agentsCard_item_image {
    width: 45px;
    padding-right: 1rem;
  }

  .agentsCard_item_name {
    // // display: block;
    width: 220px;
    line-height: 22px;
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
    margin: 0;
  }

  .agentsCard_item_email {
    line-height: 22px;
    font-size: 12px;
    color: #747678;
    margin: 0;
    margin-top: -5px;
  }
}

.agentsCard__Body {
  flex: 1;
  overflow: auto;
}

.agentsCard__Footer {
  background: #e8e8e8;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;

  span {
    font-size: 0.8rem;
  }
}

.agentsCard__toggler {
  cursor: pointer;
  width: 50px;
  height: 50px;
  background: url('../../images/svgs/search.svg') no-repeat;
  background-color: #ffffff;
  background-size: 30px 25px;
  background-position: 50% 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 50px;
  z-index: 3001;
  position: absolute;
  top: 50%;
  right: 275px;
  transition: 0.3s ease-in;
}

.agentsCard__hide {
  height: 0%;
}

.agentsCard__toggler-move {
  right: 0;
}

.agentsSearchBox {
  // margin-bottom: 0.5rem;
  padding: 10px;
}

.mapMarker {
  position: relative;
}

.mapMarker_details {
  p {
    margin: 0;
  }
}

.agToggle {
  position: absolute;
  top: 66px;
  right: 50px;
  background: white;
  height: 25px;
  width: 65px;
  z-index: 1;
  cursor: pointer;

  span {
    font-size: 0.8rem;
    margin-left: 0.4rem;
  }

  img {
    margin-left: 0.5rem;
    transition: 0.3s ease-in;
  }
}

.polToggles {
  position: absolute;
  top: 0px;
  right: 40px;
  margin: 10px;
  z-index: 1000;

  .polToggle {
    background: white;
    min-width: 65px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    padding: 0px 17px;
    height: 40px;
    border-radius: 2px;
    display: table-cell;
    vertical-align: middle;
    position: relative;

    span {
      font-size: 18px;
      margin-left: 0.4rem;
    }

    img {
      margin-left: 0.5rem;
      transition: 0.3s ease-in;
    }
  }
}