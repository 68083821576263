.side-bar-bg {
  height: 100%;
  width: $sidebar-width;
  position: fixed;
  top: 45px;
  left: 0;
  overflow-x: auto;
  background: url('../../images/svgs/sidebar-outline.svg') no-repeat bottom,
    linear-gradient($sidebar-color-primary, $sidebar-color-secondary);
  background-size: contain;
  z-index: 2002 !important;
  transition: all 0.3s ease-in;

  @media screen and (max-width: 1199px) {
    width: $sidebar-width-s;
  }

  @media screen and (max-width: 767px) {
    margin-left: -$sidebar-width;
    background-image: none;
    background: linear-gradient(
      $sidebar-color-primary,
      $sidebar-color-secondary
    );
  }
}

.side-bar-small {
  @extend .side-bar-bg;
  width: $sidebar-width-narrow;
}

.showon_mobile {
  @media screen and (max-width: 767px) {
    margin-left: 0px;
  }
}

.sidebar_overlay {
  @media screen and (max-width: 767px) {
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2001;
  }
}

.non-active-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  padding-left: 19px;
  margin-bottom: 5px;
  transition: all 0.3s ease;
  background: linear-gradient(to right, rgba(black, 0.1) 50%, transparent 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  img {
    margin-right: 1rem;
  }

  i {
    margin-right: 1.3rem;
  }
  &.active {
    background: $color-hover-blue;
    &:hover {
      background-position: left bottom;
    }
    &:focus {
      text-decoration: none;
    }
  }

  &:hover {
    background-position: left bottom;
  }

  &.form {
    cursor: pointer;
    background-color: rgba(white, 0.4);
  }
}
