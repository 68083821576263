.detailedViewModal {
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    background: $color-primary;
    color: white;
  }

  .statusBar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30px;
    border-radius: 5px 5px 0 0;
  }

  .ant-modal {
    top: 50px;
    width: 90% !important;
  }

  .ant-collapse-content-box {
    padding-bottom: 100px !important;
  }

  .ant-collapse-content {
    overflow: visible;
  }

  .detailedViewModalBody {
    display: flex;

    .detailedViewModalBody--main {
      // flex: 3;
      width: 75%;
      // width: calc(100% - 240px);
    }

    .detailedViewModalBody--Sidebar {
      width: 25%;
      // flex: 1;
      padding-left: 0.8rem;
    }
  }

  .ant-modal-body {
    background-color: rgba(#000000, 0.06);
    padding: 1rem 1.5rem;
    padding-top: 35px;
  }

  .responsesView--Overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(black, 0.45);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3000;
  }

  .responsesView {
    padding-top: 0.4rem;
    background-color: white;
    height: 65vh;
    overflow: auto;
    position: relative;
    // z-index: 10000000 !important;
  }

  .responseDiv {
    padding: 0.4rem 0.8rem;
    // padding-top: 0.5rem;
    border-bottom: 1px solid $color-grey;

    &.different {
      background-color: rgba(red, .2);
    }

    &:last-child {
      border-bottom: none;
    }

    .colorTitle {
      color: #d20000 !important;
    }

    .responseDiv--error {
      position: absolute;
      bottom: -20px;
      color: #d20000;
      font-size: 0.75rem;
      font-weight: 600;
    }

    .formItem {
      position: relative;
      display: flex;

      .ant-form-item {
        flex: 1;
      }

      input {
        background-color: #fbfbfb !important;
      }

      .ant-select-selection {
        background-color: #fbfbfb !important;
      }
    }

    .colorInput {
      input {
        border: 1px solid rgba(#d20000, 0.8) !important;
      }

      .ant-select-selection {
        border: 1px solid rgba(#d20000, 0.8) !important;
      }
    }

    .formItem--actions {
      position: relative;
      max-width: 300px;
      height: 26px;
      margin-left: 50px;
      margin-bottom: 10px;
    }

    .ant-form-item-children {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .responseDiv--Question {
      display: flex;
      justify-content: space-between;
      color: #000000;
      width: 86%;

      .showRejected {
        font-size: 0.7rem;
        color: #d20000;
      }

      .hideRejected {
        visibility: hidden;
      }
    }

    .responseDiv--Answer {
      color: $color-primary;
      font-weight: bold;
    }
  }

  .detailedViewModalBody__BottomBtns {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }

  .ResponseDetailsDiv {
    padding: 0.8rem 0;

    .ResponseDetailsDiv--label {
      color: $color-muted;
    }

    .ResponseDetailsDiv--value {
      color: $color-primary;
      font-weight: bold;
    }
  }
}

.responseCommentBox {
  background: #fbfbfb;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  position: absolute;
  padding-bottom: 0.5rem;
  right: 0px;
  top: -10px;
  z-index: 10006;
  width: 300px;

  .AddCommentPopover__Heading {
    font-size: 0.8rem;
    color: $color-muted;
    margin-bottom: 1rem;
  }

  .AddCommentPopover__Footer {
    margin-top: 0.4rem;
    display: flex;
    justify-content: space-between;
    padding: 0 0.4rem;

    i {
      font-size: 1rem;
      margin-left: 0.75rem;
      cursor: pointer;
    }
  }
}

.ant-select-dropdown,
.ant-calendar-picker-container,
.ant-cascader-menus,
.ant-dropdown,
.ant-popover {
  z-index: 10002 !important;
}

.ant-notification {
  z-index: 99999 !important;
}

.AddCommentPopover {
  .ant-popover-inner {
    background-color: $color-grey;
    width: 380px;
  }

  .AddCommentPopover__Heading {
    font-size: 0.8rem;
    color: $color-muted;
    margin-bottom: 1rem;
  }

  .AddCommentPopover__Footer {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;

    i {
      font-size: 1rem;
      margin-left: 0.75rem;
      cursor: pointer;
    }
  }
}