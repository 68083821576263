.ins-settings-card {
  background-color: $color-white;
  flex-direction: column;
  min-height: 70vh;
  width: 100%;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);

  @media screen and (min-width: 1600px) {
    // width: 80%;
  }
}

.settings-side-nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: auto;

  a {
    color: $color-dark-grey;
    display: inline-block;
    padding: 1rem;
    border: 1px solid #eeeeee;
    transition: cubic-bezier(0.23, 1, 0.32, 1);
    width: 100%;
    // border-bottom: 2px solid transparent;
    text-align: center;

    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
      text-decoration: none;
      border-top: 2px solid rgba(0, 0, 0, 0.03);
    }
  }
}

.settings-sub-div {
  padding: 0rem 2rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 767px) {
    padding: 0;
  }
}

.active-settings-link {
  border-top: 2px solid $color-primary !important;
  color: $color-primary !important;
  border-bottom: 2px solid transparent !important;

  @media screen and (max-width: 767px) {
    // border-bottom: 2px solid transparent !important;
    border-top: 2px solid $color-primary !important;
  }
}
