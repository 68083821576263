.receiptFormModal {
  th.column-amount,
  td.column-amount {
    text-align: right !important;
  }

  @media screen and (min-width: 767px) {
    width: 700px !important;
  }

  .receiptFormModal__Table {
    margin-top: 2.5rem;

    .ant-table-thead > tr > th {
      background: #dadff2 !important;
      font-weight: bolder;
      padding: 8px 16px;
    }
  }

  .receiptFormModal__logo {
    margin-top: 2rem;
    text-align: left;
    img {
      width: 68px;
    }
  }

  .receiptFormModal__Header {
    font-size: 1.3rem;
    font-weight: bold;
    color: black;
    margin: 1rem 0;
    text-align: right;
  }
}
