body {
  padding: 0;
  margin: 0;
}

textarea {
  resize: none;
}

.app-content {
  background: #f8f9fd;
  padding: 0 !important;
}

.page-sub-label {
  margin-bottom: 40px;
}

.create-button {
  height: 48px !important;
  width: 100%;
}

.app-bar {
  background: #fa8b1d !important;
}

.app-header {
  border-bottom: 3px solid #fa8b1d;
}

.circle {
  border-radius: 50%;
}

a,
a:hover {
  text-decoration: none;
}

.create-q-title {
  font-size: 25px;
  padding: 5px 0;
  text-align: center;
}

.sub-text {
  font-size: 14px;
  color: #525050;
  display: inline-block;
}

.sub-textt {
  display: block;
  line-height: 25px;
}

.bold {
  font-weight: bold;
}

.sub-text-value {
  font-size: 13px;
  color: #525050;
}

.create-button-dialog {
  height: 46px !important;
  padding: 0 20px !important;
  color: #fff !important;
}

.progress-bar-circle {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: -5px;
  margin-right: 12px;
  line-height: 25px;
}

.question-left-panel {
  background: #e1f5fe;
  height: 100vh;
}

.text-input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: 1px solid #d9d9d9;
  padding: 6px 15px;
  margin: 0px;

  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  outline: none;
  width: 100%;
  font-size: 14px;
  height: 45px;
}

.useful-tips {
  background: #19ace2;
  border-radius: 4px;
  margin: 30px 17px;
  height: 140px;
  padding: 20px;
}

.box-shadow {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
}

.question-type {
  cursor: pointer;
  width: auto;
  margin: 0 0 10px;
  padding: 6px 15px;
  border: 1px solid #ccc;
}

.char-length {
  width: 49%;
  display: inline-block;
  margin-bottom: 10px;
}

.word-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-image {
  border-radius: 50%;
  /* width: 100px; */
  height: 100px;
}

.app-border {
  border: 1px solid #d9d9d9;
  background: #ffffff;
}

.soft-heading {
  font-size: 15px;
  color: #525050;
}

.bold-heading {
  font-size: 15px;
  color: #525050;
  font-weight: bold;
}

.page-inner {
  padding: 0 15px;
}

.inline-block {
  display: inline-block;
}

.insyt-btn {
  padding: 0 15px 0 25px;
  color: #fff;
  font-size: 13px;
}

.open-textarea {
  height: 100px;
}

.faint-text {
  font-size: 12px;
}

.check-box-widget>div>div div:first-child {
  margin-right: 5px !important;
}

.check-box-widget>div>div label {
  color: #525050 !important;
  font-size: 14px;
}

.forgot-pass-email-sent {
  width: 650px;
  margin: 50px auto;
  background: #fff;
  box-shadow: 0px 21px 19px 0px #eee;
  border-radius: 4px;
  padding: 50px;
  text-align: center;
  height: 340px !important;
}

.back-white {
  background: transparent;
}

.tag-item {
  background: #cfe9fe;
  margin-right: 9px;
  border: 1px solid #239045;
  padding: 1px 1px;
  margin-bottom: 5px;
  font-size: 13px;
  color: #525050;
  border-radius: 20px;
  height: 27px;
}

.inline-block {
  display: inline-block;
}

.tag-cloud-container {
  width: 70%;
  height: 90px;
  background: #f7f7f7;
  margin-bottom: 10px;
  padding: 10px;
  overflow-y: auto;
}

.input-tag-cloud {
  outline: none !important;
  border: none;
  background: #f7f7f7;
  font-size: 12px;
  width: 100%;
}

.input-tag-cloud:active,
.input-tag-cloud:hover {
  outline: none !important;
}

.close {
  padding: 6px 7px;
  border-radius: 50%;
  background: #ccc;
  cursor: pointer;
  font-size: 9px;
  margin-left: 5px;
  font-weight: bold;
  height: 20px;
  width: 20px;
}

.info-text-m {
  font-size: 14px;
  color: #525050;
  padding: 6px 0;
}

.rc-slider-tooltip-inner {
  background: rgba(108, 108, 108, 0.8) !important;
}

.circle-label {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #bbb;
  padding: 2px 6px;
  font-size: 12px;
  margin-right: 4px;
  border: bo;
  margin-bottom: 2px;
}

.title-text {
  color: #525050;
  font-size: 15px;
  margin-bottom: 10px;
}

.section-collapse {
  text-transform: uppercase;
  color: #525050;
  font-size: 13px;
  font-weight: bold;
}

.lighter-borderless-btn {
  background: none;
  float: right;
  font-size: 12px;
  color: #e3611c;
  margin-bottom: 12px;
}

.dialog-title {
  font-size: 16px !important;
  color: #525050 !important;
  text-transform: uppercase;
}

.page-text-color {
  color: #525050;
  margin: 5px 0;
}

.text-small {
  font-size: 13px;
}

.text-normal {
  font-size: 16px;
}

.text-large {
  font-size: 20px;
}

.link,
.link:hover {
  color: #e3611c;
  font-size: 12px;
  margin-left: 10px;
}

.feedback-paper~div {
  position: absolute;
  top: 0;
}

.question-dependencies-tag-cloud {
  width: 100%;
  height: 45px;
  display: inline-block;
}

.question-dependencies div:first-child {
  width: 250px !important;
}

.filter-dependencies div:first-child {}

.error-text {
  padding: 10px;
  font-style: italic;
  color: #f00;
  height: 25px;
  font-size: 12px;
  padding: 5px 0px;
  width: 100%;
}

.required-field {
  color: rgb(204, 0, 0);
  display: block;
  height: 25px;
  font-size: 12px;
  padding: 5px 0px;
  width: 100%;
}

.x-text-constraint {
  margin: 8px 0;
  font: 15px;
}

.sidebar-divider {
  border-bottom: 1px inset #ccc;
  padding: 12px 0;
  min-height: 80px;
}

.sidebar-divider:hover {
  background: #f1f1f1;
}

.side-surveys-view {
  font-size: 14px;
  font-weight: bold;
  color: #0474c6;
}

.side-survey-title {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.side-survey-desc {
  font-size: 14px;
  color: #525050;
  letter-spacing: 0.3px;
}

.side-survey-date {
  font-size: 11px;
  color: rgba(82, 80, 80, 0.7);
}

.project-code {
  background: #e5eef7;
  padding: 15px 20px;
}

.rounded-btn {
  border-radius: 30px !important;
  padding: 0 0px !important;
}

.list-style-paging {
  list-style: none;
  display: inline-block;
  margin: 2px;
  padding-left: 0;
}

.page-nav {
  color: #fff;
  font-size: 14px;
  padding: 7px 12px;
  margin: 0 10px 10px 0;
  border: none;
  border-radius: 3px;
  cursor: pointer;

  /* min-width: 500px; */
}

.page-navOptionIcons {
  display: flex;
  margin-left: 0.5rem;
}

.pageEditIcon {
  visibility: hidden;
  /* display: none; */
}

.add-page-btn {
  background: #fff !important;
  color: #525050 !important;
  font-size: 14px !important;
  border: 1px solid #d9d9d9;
  outline: none;
  transition: all 0.1s ease-in;
  min-width: 120px;
  max-width: 270px;
}

.add-page-btn-2 {
  background: #fff !important;
  color: #525050 !important;
  font-size: 14px !important;
  border: 1px solid #d9d9d9;
  outline: none;
  transition: all 0.1s ease-in;
}

.add-page-btn.active {
  position: relative;
  background: #2447b3 !important;
  color: #fff !important;
  border: 1px solid #2447b3 !important;
}

#2447b3

/* Widgets */
/*spinner container*/
.spinner-container {
  margin: 0 auto;
  padding: 20px 0;
  width: 100%;
}

.spinner {
  font-size: 10px;
  height: 30px;
  text-align: center;
}

.spinner>div {
  animation: 1.2s ease-in-out 0s normal none infinite running sk-stretchdelay;
  background-color: #1a64f0;
  display: inline-block;
  height: 100%;
  margin: 0 3px 0 0;
  width: 7px;
}

.spinner .rect2 {
  animation-delay: -1.1s;
  background-color: #fa8b1d;
}

.spinner .rect3 {
  animation-delay: -1s;
}

.spinner .rect4 {
  animation-delay: -0.9s;
  background-color: #fa8b1d;
}

.spinner .rect5 {
  animation-delay: -0.8s;
}

.spinner .label {
  animation: none;
  background: none !important;
  display: inline-block;
  height: 100%;
  margin: 0 3px 0 0;
  width: 100%;
}

@-webkit-keyframes sk-stretchdelay {

  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {

  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.e-check-box>div>div div:first-child {
  margin-right: 5px !important;
  color: #525050;
}

.e-check-box svg {
  fill: #888 !important;
}

.radio-group>div {
  width: 35% !important;
  color: #525050;
  font-size: 13px !important;
}

.radio-group>div>div div:first-child {
  margin-right: 5px !important;
  color: #525050;
}

.q-builder-text-bg {
  background: #f5f6f8;
  border: none;
}

.q-builder-text-bg:focus {
  outline: 0px !important;
}

.rc-slider-track {
  background-color: #f57f41 !important;
}

.rc-slider-handle {
  border: 2px solid #f57f41 !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-moz-inner-spin-button,
input[type='number']::-moz-outer-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

body {
  margin: 0;
  padding: 0;
  font-family: Lato, sans-serif;
}

.add_option_form {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0.5rem 0 1rem;
}

.add_option_form input,
.add_option_form textarea {
  width: 100%;
}
.add_option_form input {
  height: 35px;
}

.add_option_form input:-webkit-autofill {
  box-shadow: 0 0 0 30px #f5f6f8 inset;
  -webkit-box-shadow: 0 0 0 30px #f5f6f8 inset;
}

.add_option_form button {
  font-family: Lato, sans-serif;
  font-size: 13px;
  background-color: white;
  border: none;
  border: 1px solid #d1d9e2;
  border-radius: 3px;
  padding: 0.3rem 0;
  width: 20%;
  height: 45px;
}

.add_option_form button:hover {
  cursor: pointer;
}

.remove_span {
  color: #ee6b19;
  font-weight: 300;
  margin-left: 0.8rem;
}

.remove_span:hover {
  cursor: pointer;
}

.custom_input_error_msg {
  visibility: hidden;
  font-size: 0.75rem;
  color: red;
  margin: 0.1rem 0;
  transition: all 0.2s ease-in;
}

.custom_input_error_msg_show {
  visibility: visible;
}

@media only screen and (max-width: 768px) {
  .add_option_form input {
    width: 73%;
  }
}