.FormTemplatesCard {
  min-height: 56px;
  margin-bottom: 2px;
  background: white;
  width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 10px 10px 10px 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  .card-content {
    flex:1;
    overflow: hidden;
    word-wrap: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .card-icon,
  .card-menu{
    flex-shrink: 0;

  }
  .card-menu{
    svg {
      opacity: 0.6;
    }
    button {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .card-icon {
    display: flex;
    justify-content: center;
    border-radius: 50%;
    align-items: center;
    width: 32px;
    background: #f3f5f7; //#05548d; //
    height: 32px;
  }
}