.authWrapper {
  background: rgba(235, 237, 239, 0.77);
  min-height: 100vh;
  display: flex;
  flex-direction: row;

  .authWrapper__Dock {
    // background: $color-primary;
    background: url('../../images/svgs/bg.svg') no-repeat;
    background-size: cover;
    width: 375px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 50px;

    .authWrapper__DockBottom {
      padding-bottom: 4rem;

      p {
        color: white;
        font-size: 2rem;
      }

      span {
        color: white;
        font-size: 0.9rem;
      }
    }

    @media screen and (max-width: 880px) {
      display: none;
    }
  }

  .authWrapper__Body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;

    .formContainer {
      min-width: 60%;
      // height: 100%;

      overflow: auto;

      form {
        max-width: 90%;
        margin: 0 auto;
      }

      @media screen and (max-width: 880px) {
        min-width: 60%;
      }

      @media screen and (min-width: 1200px) {
        // max-width: 450px !important;
        form {
          // max-width: 450px !important;
          margin: 0 auto;
        }
      }
    }

    .ant-form-item {
      margin-bottom: 0.5rem !important;
    }

    .ant-form-item-control-wrapper {
      margin-top: -0.7rem;
    }

    .loginForm {
      .loginForm__linksDiv {
        p {
          margin-top: 0.8rem;
        }
      }

      .loginForm__Btn {
        margin-top: 1.5rem;
        margin-bottom: 1.35rem;
      }
    }
  }

  .authWrapper__FormCard {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    display: flex;
    width: 85vw;
    min-height: 75vh;
    background-color: white;

    @media screen and (min-width: 1200px) {
      width: 70vw !important;
    }

    @media screen and (max-width: 1100px) {
      width: 90% !important;
    }

    @media screen and (max-width: 778px) {
      box-shadow: none;
    }

    .authWrapper__FormCardRight {
      padding: 1rem;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 778px) {
        padding: 0 !important; // display: none;
      }
    }

    .authWrapper__FormCardLeft {
      background: url('../../images/svgs/bg.svg') no-repeat;
      background-size: cover;
      width: 45%;

      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 6rem 4rem 6rem;
      color: $color-white;

      @media screen and (max-width: 778px) {
        display: none;
      }

      .authWrapper__FormCardLeftTop {
        p {
          font-size: 1.5rem;
        }
      }

      .authWrapper__FormCardLeftBottom {
        p {
          font-size: 1.95rem;
          font-weight: bold;
          margin-bottom: 0.5rem;
        }

        span {
          font-weight: 300;
        }
      }
    }
  }

  .authWrapper__Footer {
    width: 85vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    padding-top: 1rem;
    // margin-top: 0.5rem;
    font-size: 0.8rem;

    .ant-select-selection {
      border: none;
      font-size: 0.8rem;
      margin-left: 20px;
      background-color: transparent;
    }

    @media screen and (min-width: 1200px) {
      max-width: 70vw !important;
    }

    @media screen and (max-width: 778px) {
      // padding-top: 1.2rem;
      flex-direction: column;
    }
  }
}
