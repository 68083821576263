.questionWidget {
  display: flex;
  position: relative;

  .hidden-add {
    position: absolute;
    right: 1%;
    z-index: 100;
    cursor: pointer;
  }

  .ant-dropdown-trigger {
    top: 1rem;
  }

  .hidden-add-paddings {
    top: 2.5%;
  }
}

.questionWidget__handle {
  background: url('../../images/svgs/six.svg') no-repeat;
  background-size: 12px 20px;
  background-position: 10%;
  display: flex;
  align-items: center;
  padding-left: 1.5%;
  min-width: 50px;
  background-color: #d1dde9;
  height: 45px;

  .ant-checkbox-wrapper {
    margin-left: -0.5rem;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.questionWidget__handle-noHandleImg {
  @extend .questionWidget__handle;
  background: none;
  background-color: #d1dde9;
}

.questionWidget_summaryIndicator {
  background: url('../../images/svgs/success-check-icon.svg') no-repeat;
  background-size: 10px;
  background-position: 0.8% 70%;
}

.questionWidget__handleNumber {
  font-size: 13px;
  color: #525050;
  margin-left: 25%;
  text-align: right;
  // width: 25px;
  // height: 100%;
}

.question-hint {
  width: 80%;
  display: inline-block;
  margin-bottom: 10px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.survey-content {
  background: #ffffff;
  min-height: 87px;
  width: 100%;
  border: 3px solid #d1dde9;
}

.questionWidget__collapse {
  background: url('../../images/svgs/arrow-down.svg') no-repeat;
  background-size: 10px;
  background-position: 97.2% 55%;
  background-color: white;
  height: 45px;
  overflow-y: hidden;
  padding: 10px 0;
  min-height: 45px;
  display: flex;
  flex-direction: column;

  .questionWidget__collapseInner {
    padding: 0 0 0 20px;
    margin-right: 30px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.questionWidget__Open {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  // width: 90%;
}

.question-title {
  // flex-grow: 3;
  display: inline-block;
  width: calc(100% - 250px);
  margin-right: 10px;
}

.questionWidget__advDiv {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}

.qrule__div {
  div {
    button+div {
      width: 80%;
    }
  }
}