h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1,
.h1 {
  font-size: 2.375rem;
}

h2,
.h2 {
  font-size: 1.875rem;
}

h3,
.h3 {
  font-size: 1.25rem;
}

h4,
.h4 {
  font-size: 1.125rem;
}

h5,
.h5 {
  font-size: 0.875rem;
}

h6,
.h6 {
  font-size: 0.75rem;
}

p {
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: normal;
}

.small-text {
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: normal;
}

.page-heading {
  margin: 0 !important;
  padding: 0 !important;
}

.app_container {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.mute {
  color: #8c8c8c;
}
