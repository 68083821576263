.GeofenceContainer {
  width: 100% !important;

  .GeoFenceEmptyScreen {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // height: 100%;
    padding: 2.5rem 0 1rem;

    p {
      font-weight: 600;
      margin-top: 1.4rem;
    }

    span {
      font-size: 0.75rem;
    }

    .GeoFenceEmptyScreen--top {
      padding-left: 1rem;
      padding-right: 0.5rem;
    }
  }

  .GeofenceBody {
    position: relative;
    display: flex;
    height: 100%;

    .GeofenceMap {
      position: relative;
      flex: 1;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    .GeofenceSidebar {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      width: 250px;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      overflow: auto;
      position: relative;

      .GeofenceSidebar--Bottom {
        position: fixed;
        bottom: 0px;
        left: 0px;
        padding: 0.5rem;
        width: 250px;
        background-color: white;
        height: 70px;
      }

      .eachPolygon {
        position: relative;
        border: 1px solid #e6e6e6;
        transition: all 0.4s ease;
        min-height: 90px;
        i {
          visibility: hidden;
          opacity: 0;
        }
        .eachPolygon--actions {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          span {
            cursor: pointer;
            font-size: 0.75rem;
            margin-left: 1rem;
            transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .eachPolygon--info {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0.5rem;
        background-color: white;
        // margin-bottom: -100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:hover {
          cursor: pointer;
          background-color: #f8f8f8;
          i {
            visibility: visible;
            opacity: 1;
          }
        }
      }

      .eachPolygon--infoSelected {
        background-color: #f8f8f8;
      }

      .eachPolygon--edit {
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        // margin-bottom: -100%;
        z-index: 2;
        padding: 0 0.5rem;
        transition: all 0.4s ease;
      }
      .eachPolygon--editShow {
        left: 0;
      }
    }

    .GeofenceSidebar--hidden {
      margin-left: -335px;
    }
  }


  .GeofenceSidebar__Width--toggler {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 250px;
    bottom: 5px;
    width: 40px;
    height: 40px;
    z-index: 99999;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover {
      cursor: pointer;
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
    }
  }

  button[title="Stop drawing"] ,
  button[title="Draw a shape"], {
    margin-top: 5px !important;
  }
  button[title="Stop drawing"] ,
  button[title="Draw a shape"],
  .add-coordinates-button {
    height: 40px;

    width: 40px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  button[title="Draw a shape"] {
    border-radius: 0 !important;
  }

  .add-coordinates-button:hover{
    background-color: #efefef;
  }
  .add-coordinates-button {
    margin-top: 10px !important;
    left: 85px;
    box-shadow: rgba(0 , 0 , 0 , 0.3) 0px 1px 4px -1px;
    background: none padding-box rgb(255, 255, 255);
    border: 0px;
    padding: 6px;
    text-transform: none;
    appearance: none;
    position: relative;
    cursor: pointer;
    user-select: none;
    direction: ltr;
    overflow: hidden;
    text-align: left;
    color: rgb(86, 86, 86);
    font-family: Roboto, Arial, sans-serif;
    font-size: 11px;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
  }
}


.GeofenceSidebar__EditView {
  h6 {
    margin-top: 15px;
    margin-bottom: 5px;
  }
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
